<template>
  <!--
    Used to provide data in order to add InstallationConfigListItems that are used in the videoPostprocessing themes.
  -->
  <div class="installationConfigListItemAdd">
    <LoadingPlaceholder v-if="loading || loadingInstallationTypes" />
    <template v-else>
      <template v-if="everythingIsAvailable">
        <form>
          <h4 class="m-0 mt-2">
            {{ $t('installationConfig') }}
          </h4>
          <hr class="m-0 mb-3">

          <div class="form-group m-form__group row  mb-2">
            <label class="col-4 col-form-label col-form-label">{{ $t('installation') }}</label>
            <div class="col-8">
              <select
                v-model="installationId"
                :class="['form-control', { 'is-invalid': this.$validator.errors.has('InstallationId') }]"
              >
                <option :value="null">
                  {{ $t('pleaseSelectOne') }}
                </option>
                <option
                  v-for="(installation, index) in installationList"
                  :key="`option${ index }`"
                  :value="installation.id"
                >
                  {{ installation.name }}
                </option>
              </select>
              <span
                v-show="errors.has('InstallationId')"
                class="badge badge-danger"
              >{{ errors.first('InstallationId') }}</span>
            </div>
          </div>

          <div class="form-group m-form__group row mb-2">
            <label class="col-4 col-form-label col-form-label">{{ $t('theme') }}</label>
            <div class="col-8">
              <select
                v-model="themeId"
                :class="['form-control', { 'is-invalid': this.$validator.errors.has('IntroOutroId') }]"
              >
                <option :value="null">
                  none
                </option>
                <option
                  v-for="(theme, index) in themes"
                  :key="`option${ index }`"
                  :value="theme.id"
                >
                  {{ theme.name }}
                </option>
              </select>
              <span
                v-show="errors.has('ThemeId')"
                class="badge badge-danger"
              >{{ errors.first('ThemeId') }}</span>
            </div>
          </div>

          <div class="form-group m-form__group row mb-2">
            <label class="col-4 col-form-label col-form-label">{{ $t('installationConfigListItemAddComp.introOutro') }}</label>
            <div class="col-8">
              <select
                v-model="introOutroId"
                :class="['form-control', { 'is-invalid': this.$validator.errors.has('IntroOutroId') }]"
              >
                <option :value="null">
                  none
                </option>
                <option
                  v-for="(introOutro, index) in introOutroList"
                  :key="`option${ index }`"
                  :value="introOutro.id"
                >
                  {{ introOutro.name }}
                </option>
              </select>
              <span
                v-show="errors.has('IntroOutroId')"
                class="badge badge-danger"
              >{{ errors.first('IntroOutroId') }}</span>
            </div>
          </div>

          <button
            class="btn btn-primary btn-block mb-3"
            @click.prevent="addInstallationConfig()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="plus"
            />
            <span>{{ $t('add') }}</span>
          </button>
        </form>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "InstallationConfigListItemAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      loading: true,
      loadingInstallationTypes: true,
      cutConfigList: null,
      cutConfigId: null,
      introOutroList: null,
      introOutroId: null,
      themes: null,
      themeId: null,
      installationId: null,
      installationList: null,
      installationTypeIds: null
    }
  },
  computed: {
    everythingIsAvailable: function () {
      if (!this.themes) {
        return false;
      }
      if (!this.introOutroList) {
        return false;
      }
      if (!this.cutConfigList) {
        return false;
      }
      return true;
    }
  },
  async created () {
    await this.getInstallationTypes();
    this.getData();
  },
  methods: {
    async getInstallationTypes () {
      await this.axios.get(`/Installation/GetInstallationTypes`)
        .then((response) => {
          this.installationTypeIds = [];
          let installationTypes = response.data;
          installationTypes.forEach(type => {
            if (type.name.toLowerCase().includes('skimovie') || type.name.toLowerCase().includes('eventmovie')) {
              this.installationTypeIds.push(type.id);
            }
          });
        })
        .finally(() => {
          this.loadingInstallationTypes = false;
        });
    },
    getData () {
      this.axios.all([
        this.axios.get('/VideoPostprocessing/GetAllCutConfig'),
        this.axios.get('/VideoPostprocessing/GetIntrooutros'),
        this.axios.get('/VideoPostprocessing/GetThemes'),
        this.axios.get(`/Installation/GetByInstallationTypeIds?${ this.buildInstallationTypeQueryParameters() }`)
      ]).then(this.axios.spread((getAllCutConfigResponse, introOutroResponse, themeResponse, installationsResponse) => {
          this.cutConfigList = getAllCutConfigResponse.data;
          this.introOutroList = introOutroResponse.data;
          this.themes = themeResponse.data;
          this.installationList = installationsResponse.data.sort(function (a, b) { return a.name > b.name ? 1 : -1 });
          // this.setSelectValues();
        }))
        .finally(() => {
          this.loading = false;
        });
    },
    buildInstallationTypeQueryParameters () {
      let query = "";
      this.installationTypeIds.forEach((element) => {
          if (query != "") { query += "&"; }
          query += `installationTypeIds=${ element }`;
      });
      return query;
    },
    setSelectValues () {
      this.themeId = this.themes[0].id;
      this.introOutroId = this.introOutroList[0].id;
      this.cutConfigId = this.cutConfigList[0].id;
      this.installationId = this.installationList[0].id;
    },
    addInstallationConfig () {
      let installationConfig = {
        'InstallationId': this.installationId,
        'ThemeId': this.themeId,
        'IntroOutroId': this.introOutroId
      };
      this.axios.post(`/VideoPostprocessing/AddInstallationConfig`, installationConfig)
        .then((response) => {
          if(response && response.status == 200)
          {
            this.error_clear();
            this.$snotify.success(this.$t('installationConfigListItemAddComp.successfullyUpdated'));
            this.$emit('installationConfigListItemEdit_refreshInstallationConfigList');
          }
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
